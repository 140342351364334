<template>
  <v-select
    v-model="input"
    :items="items"
    item-text="text"
    item-value="value"
    disabled
  />
</template>

<script>
export default {
  name: 'BlackPins',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      input: null,
      items: [
        { text: 'B89DB', value: 'B89DB' },
      ],
    }
  },
  watch: {
    input: function (val) {
      this.$emit('input', val)
    },
  },
  mounted: function () {
    this.input = this.value
  },
}
</script>
